@import "../../utilities/variables.scss";

.gallerySection {
  padding-bottom: 40px;
  border-top: 1px solid $color;

  h1 {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .mySwiper {
    border: 2px solid $color; 
    border-radius: 8px;

    #app { height: 100% }
    html,
    body {
      position: relative;
      height: 100%;
    }

    body {
      background: #eee;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 14px;
      color: #000;
      margin: 0;
      padding: 0;
    }

    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      height: auto;
      max-height: 800px;
      text-align: center;
      font-size: 18px;
      background: $dark-grey;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .swiper-button-prev,
    .swiper-button-next {
      color: black;
      -webkit-transition: color 0.1s ease-out;
      -moz-transition: color 0.1s ease-out;
      -o-transition: color 0.1s ease-out;
      -ms-transition: color 0.1s ease-out;
      transition: color 0.1s ease-out;
      opacity: 0.75;

      @media (max-width: $standard-desktop) {
        display: none;
      }
    }

    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      color: $color;
    }
  }

  .instaFeed {
    div {
      div {
        div {
          aspect-ratio: 1;
        }
      }
    }
  }
}