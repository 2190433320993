@import "../../utilities/variables.scss";

.newsSection {
  border-bottom: 1px solid $color;
  
  .newsEntries {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;

    .newsChild {
      width: 100%;

      a {
        text-decoration: none;

        h2 {
          text-decoration: underline;
        }

      }
      
      @media (min-width: 730px) {
        box-sizing: border-box;
        max-width: 49.2%;
      }

      @media (min-width: 1200px) {
        box-sizing: border-box;
        max-width: 32.3%;
      }
    }

    a {
      color: $color;
    }

    body {
      color: #333333;
      font-family: 'Helvetica', arial;
      height: 80em;
    }
    
    .wrap {
      padding: 40px;
      text-align: center;
    }
    
    hr {
      clear: both;
      margin-top: 40px;
      margin-bottom: 40px;
      border: 0;
      border-top: 1px solid #aaaaaa;
    }
      
    h1 {
      margin-bottom: 40px;
    }
    
    p {
      margin-bottom: 20px;
    }
    
    .btn {
      background: #428bca;
      border: #357ebd solid 1px;
      border-radius: 3px;
      color: #fff;
      display: inline-block;
      font-size: 14px;
      padding: 8px 15px;
      text-decoration: none;
      text-align: center;
      min-width: 60px;
      position: relative;
      transition: color .1s ease;
    }
    
    .btn:hover {
      background: #357ebd;
    }
    
    .btn.btn-big {
      font-size: 18px;
      padding: 15px 20px;
      min-width: 100px;
    }
    
    .btn-close {
      color: $color;
      font-size: 35px;
      text-decoration: none;
      position: absolute;
      right: 12px;
      top: 0;
      padding-left: 50px;
    }
    
    .btn-close:hover {
      color: #919191;
    }
    
    .modal:before {
      content: "";
      display: none;
      background: rgba(0, 0, 0, 0.6);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
    
    .modal:target:before {
      display: block;
    }
    
    .modal:target .modal-dialog {
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }

    .modal-dialog {
      overflow-y: auto;
      overflow-x: hidden;
      background: $grey;
      border: $dark-grey solid 1px;
      border-radius: 5px;
      width: 100%;
      max-width: 750px;
      max-height: 60%;
      position: fixed;
      top: 20%;
      bottom: auto;
      z-index: 11;
      -webkit-transform: translate(0, 500%);
      -ms-transform: translate(0, 500%);
      transform: translate(0, 500%);
      -webkit-transition: -webkit-transform 0.3s ease-out;
      -moz-transition: -moz-transform 0.3s ease-out;
      -o-transition: -o-transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      left: 0;

      @media (max-width: 750px) {
        top: 0;
        height: 100%;
        max-height: 100%;
        
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          background: transparent;
          width: 0px;
        }
      }  

      @media (min-width: 750px) {
        left: 15%;
        max-width: 70%;
        right: auto;
      }

      @media (min-width: 1300px) {
        left: 20%;
        max-width: 60%;
        right: auto;
      }

      @media (min-width: 1450px) {
        left: 25%;
        max-width: 50%;
        right: auto;
      }

      @media (min-width: 1700px) {
        left: 30%;
        max-width: 40%;
        right: auto;
      }
    }
    
    .modal-body {
      padding: 20px;
      max-width: 100%;
      text-align: center;

      p {
        margin-top: 0px;
        font-size: 14px;
        text-align: left;
      }

      a {
        text-decoration: underline;
      }

      a:hover {
        color: $light-grey;
      }

      .newsImage {
        border: 1px solid $dark-grey;
        border-radius: 4px;
        margin-top: 15px;
        max-width: 100%;
        max-height: 100%;

        @media (min-width: 400px) {
          max-width: 400px;
          max-height: 400px;
        }
      }
    }

    .modal-header,
    .modal-footer {
      padding: 10px 20px;
    }
    
    .modal-header {
      border-bottom: $color solid 1px;

      h2 {
        max-width: 95%;
        text-decoration: underline;
      }

      p {
        margin-bottom: 10px;
      }
    }
    
    .modal-header h2 {
      font-size: 20px;
    }
    
    .modal-footer {
      border-top: #eeeeee solid 1px;
      text-align: right;
    }
    
    #close {
      display: none;
    }
  }

  .newsEntry {
    border: 1px solid $grey;
    border-radius: 6px;
    padding: 0 15px;
    margin: 5px 0;
    transition: transform .2s;

    @media (min-width: $standard-desktop) {
      margin: 10px 0;
    }
  }

  .newsEntry:hover {
    transform: scale(1.025);
  }

  h2 {
    font-weight: 500;
    color: $color;
    font-size: 16px;
  }

  h1 {
    margin: 0 0 10px 0;
  }

  h2, p {
    margin: 10px 0;
  }

  p {
    font-size: 12px
  }
}



