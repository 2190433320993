@import "utilities/variables";

html {
  scroll-behavior: smooth;
}

body {
  background-color: $dark-grey;
  
  
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color;

  h1, h2, h3, p {
    margin: 16px 0;
  }

  h1 {
    font-weight: 550;
    font-size: 44px;

    @media (max-width: $standard-desktop) {
      font-size: 42px;
    }
  }

  p {
    font-size: 16px;

    @media (max-width: $standard-desktop) {
      font-size: 14px;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.gold {
  color: $color;
}

.show-desktop {
  display: flex;

  @media (max-width: $standard-desktop) {
    display: none;
  }
}

.show-mobile {
  display: flex;

  @media (min-width: $standard-desktop) {
    display: none;
  }
}

.section {
  padding: 0px 50px;
  max-width: $ultra-desktop;
  

  @media (max-width: $standard-desktop) {
    padding: 25px 25px;
  }

  @media (min-width: 1400px) {
    margin: 0 auto;
  }
}

.tb-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}