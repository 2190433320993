@import "../../utilities/variables";

.socialIcon {
  width: 30px;
  height: 30px;
  transition: transform .2s;
  margin-left: 20px;
}

.socialIcon:hover {
  transform: scale(1.5);
  //border-bottom: 1px solid $grey;
}