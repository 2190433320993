@import "../../utilities/variables.scss";

.blurbSection {
  padding-top: 100px;
  padding-bottom: 110px;

  @media (max-width: $standard-desktop) {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  h1, p {
    text-align: center;
    max-width: 1100px;

    @media (min-width: $standard-desktop) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .blurbText {
    h1 {
      font-weight: 550;
    }
  }
}