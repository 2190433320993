@import "../../utilities/variables.scss";

.footer {
  p {
    font-weight: 550;
  }

  border-top: 1px solid $color;
  height: 120px;
  background-color: $dark-grey;

  .footerContent {
    padding: 30px 50px;
    max-width: 1600px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1150px) {
      display: block;
      text-align: center;

      p {
        display: none;
      }
    }

    @media (max-width: $standard-desktop) {
      padding: 30px 25px;
    }

    .contactFooterSection {
      .contactAnchor {
        text-decoration: none;
        color: $color;
        
        h2 {
          transition: transform .2s;
          font-weight: 550;

          @media (max-width: 1150px) {
            margin-bottom: 28px;
          }
        }

        h2:hover {
          transform: scale(1.05);
        }
      }
    }

    .copyrightFooter {
      .copyrightText {
        font-weight: 550;

        @media (max-width: 1150px) {
          margin-top: 0;
        }
      }
    }

    .socialFooterText {
      margin-left: 20px;
    }
  }
}