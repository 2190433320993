@import "../../utilities/variables.scss";

.coachesDivs {
  @media (max-width: $standard-desktop) {
    display: block;
  }
  @media (min-width: $standard-desktop) {
    display: flex;
  }

  .coachesText, .coachesImage {
    width: 50%;

    @media (max-width: $standard-desktop) {
      width: 100%;
      height: 100%;
    }
  }

  .coachesText {
    margin: auto;
    margin-right: 25px;
    padding-bottom: 32px;

    h1 {
      margin-top: 0px;
    }
    
    h1, p {
      text-align: center;
    }

    p {
      font-weight: 500;
      font-size: 24px;

      @media (max-width: $standard-desktop) {
        font-size: 20px;
      }

    }
    
    @media (max-width: $standard-desktop) {
      text-align: center;
    }
  }

  .coachesImage {
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    transition: transform .2s;

    @media (max-width: $standard-desktop) {
      margin: 0 0 0 0;
    }
    @media (min-width: $standard-desktop) {
      margin: 0;
    }
    
    img {
      width: 100%;
      height: 100%;
      border: 1px solid $color;
      border-radius: 6px;
    }
  }

}

.aboutSection {
  color: $color;
  display: flex;
  border-bottom: 1px solid $color;

  @media (max-width: $standard-desktop) {
    display: block;
  }

  .aboutText, .aboutImage {
    width: 50%;

    @media (max-width: $standard-desktop) {
      width: 100%;
      height: 100%;
    }
  }

  .aboutText {
    margin: auto;
    padding-bottom: 32px;

    h1 {
      margin-top: 0px;
    }
    
    h1, p {
      text-align: center;
    }

    p {
      font-weight: 500;
    }
    
    @media (max-width: $standard-desktop) {
      text-align: center;
    }
  }

  .aboutImage {
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    transition: transform .2s;

    @media (max-width: $standard-desktop) {
      margin: 10px 0 0 0;
    }
    
    img {
      width: 100%;
      height: 100%;
      border: 1px solid $color;
      border-radius: 6px;
    }
  }

  .aboutImage:hover {
    transform: scale(1.025);
  }

  .aboutImages {
    display: flex;

    @media (max-width: $standard-desktop) {
      display: block;
    }

    .aboutImage {
      @media (max-width: $standard-desktop) {
        margin-bottom: 25px;
      }
    }
  }

  .coachImages {
    display: flex;

    @media (max-width: $standard-desktop) {
      display: block;
    }

    .coachImage {
      @media (max-width: $standard-desktop) {
        margin-bottom: 25px;
      }
    }
  }

  .coachImage:hover {
    transform: scale(1.025);
  }

  .coachImage {
    
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    transition: transform .2s;

    @media (max-width: $standard-desktop) {
      margin: 10px 0 0 0;
    }

    @media (min-width: $standard-desktop) {
      padding-top: 30px;
      height: 500px;
      margin: auto;
    }
    
    img {
      width: 100%;
      height: 100%;
      border: 1px solid $color;
      border-radius: 6px;
    }
  }
}