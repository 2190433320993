@import "../../utilities/variables.scss";

.infoSection {
  margin-bottom: 24px;

  .infoTitle {
    margin-bottom: 24px;
    margin-top: 30px;
  }

  .infoButtons {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 830px) {
      display: block
    }

    @media (min-width: 830px) {
      justify-content: space-between;
    }

    .infoAnchor {
      text-decoration: none;
      color: $color;
      width: 100%;
      transition: transform .2s;

      @media (min-width: 830px) {
        width: 49%;
      }
    }

    .infoAnchor:hover {
      transform: scale(1.025);
    }
  
    .infoButton {
      background-color: $dark-grey;
  
      border-radius: 4px;
      padding: 6px;
      margin-top: 16px;
      margin-bottom: 16px;
      border: 1px solid $color;
  
      .infoInnerBorder {

        border-radius: 4px;
        padding: 24px 24px;

        h2 {
          text-align: center;
          font-weight: 550;
          margin: 0;
          color: $color;
        }
      }
    }
  }
}