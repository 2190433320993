@import "../../utilities/variables.scss";

.contactUsSection {

  .contactView {
    display: flex;
  }

  .detailsContainer {
    width: 50%;
    align-self: center;
  }




  .formContainer {
    width: 50%;
    border-radius: 5px;
    background-color: $grey;
    padding: 20px;

    ::placeholder {
      font-style: italic;
      color: white;
    }

    .messageField {
      height: 260px;
      resize: none;
    }

    input[type=text], input[type=email], select, textarea {
      width: 100%;
      padding: 12px;
      border: 1px solid $dark-grey;
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;
      resize: vertical;
      background: $light-grey;
    }

    input[type=submit] {
      background-color: $dark-grey;
      color: $color;
      padding: 12px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      -webkit-transition: background-color 0.5s ease-out;
      -moz-transition: background-color 0.5s ease-out;
      -o-transition: background-color 0.5s ease-out;
      -ms-transition: background-color 0.5s ease-out;
      transition: background-color 0.5s ease-out;
    }
    
    input[type=submit]:hover {
      background-color: $color;
      color: $dark-grey;
    }
  }
}