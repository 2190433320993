@import "../../utilities/variables";

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color;
  
  .headerLogo {
    .logo {
      max-height: 90px;
      max-width: 180px;
      transition: transform .2s;

      @media (max-width: $standard-desktop) {
        max-height: 50px;
        max-width: 100px;
      }
    }

    .logo:hover {
      transform: scale(1.2);
    }
  }

  .headerLinks {
    .boldFont {
      font-weight: 550;
    }

    a {
      padding: 5px 8px;
      margin: 0 8px;
      text-decoration: none;
      transition: transform .2s;
      font-size: 20px;
      font-weight: 360;
    }

    a:hover {
      transform: scale(1.5);
    }
  }

  .headerBurgerMenu {
    .hamburgerIcon {
      width: 30px;
      height: 30px;
    }
  }
}